<template>
        <Layout :style="'min-height:'+ innerHeight + 'px'">
            <Header>
              <div class="title_nav_box">
                <!-- <div style="color:#FFD878;font-size:32px">Logo</div> -->
                <!-- <img style="width:62px;margin-left:25px" src="../../assets/logo.png"> -->
                logo
                <div class="right_box">
                  <!-- <div><Input @on-change="inputchange" v-model="searchContent" placeholder="请输入" style="width: auto"><Icon type="ios-search" slot="suffix" /></Input></div> -->
                  <!-- <div><Button style="background: #FFD878;color: #000;border-color: none;" class="ml-5">搜索</Button></div> -->
                  <div class="layout-nav ml-20">
                    <MenuItem name="logout" style="display: flex;align-items: center">
                      <!-- <div class="bg-circle">{{ userInfo.account?userInfo.account.substring(0,1):'' }}</div><span style="color:#000;">{{ userInfo.account }}</span> -->
                      <div class="bg-circle">{{ '管' }}</div><span style="color:#000;">超级管理员</span>
                    </MenuItem>
                    
                    <div class="show-menu">
                      <div @click="goPwd">修改密码</div>
                      <div @click="logout">退出登录</div>
                    </div>
                  </div>
                </div>
              </div>
            </Header>
            <Layout style="z-index:1">
                <Sider hide-trigger :style="{background: '#fff'}">
                  <Menu :active-name="String($route.name)" ref="menu" theme="light" width="auto">
                    <div>
                      <div v-for="(item,index) in muen" :key="index">
                        <div v-if="item.children.length>0">
                          <Submenu :name="item.unique_auth" >
                            <template slot="title" style="display: flex;align-items: center">
                              <!-- <Icon :type="item.icon" size="25"/> -->
                              {{ item.title }}
                            </template>
                            <MenuItem  v-for="(child,idx) in item.children" :key="idx" :name="child.unique_auth" :to="child.src">
                              <div>
                                {{ child.title }}
                              </div>
                            </MenuItem>
                          </Submenu>
                        </div>
                        <div v-else>
                          <MenuItem :name="item.unique_auth" :to="item.src" style="display: flex;align-items: center">
                            <!-- <Icon :type="item.icon" size="25"/> -->
                            <div>{{ item.title }}</div>
                          </MenuItem>
                        </div>
                      </div>
                    </div>
                  </Menu>
                </Sider>
                <Layout :style="{padding: '20px'}">
                    <Content :style="{ background: '#fff'}">
                        <router-view></router-view>
                    </Content>
                </Layout>
            </Layout>
            <!-- 查看弹框 -->
            <Modal v-model="showPwd" @on-cancel="closeForm" :mask-closable="false" width="510">
                <p slot="header" style="text-align:center">修改密码</p>
                <div class="modal_contene">
                    <Form label-position="left" :label-width="100">                
                        <FormItem label="原密码">
                            <Input placeholder="请输入原密码" type="password" v-model="formData.old_pwd"></Input>
                        </FormItem>
                        <FormItem label="新密码">
                            <Input placeholder="请输入新密码" type="password" v-model="formData.new_pwd"></Input>
                        </FormItem>
                    </Form>
                </div>
                <div slot="footer">
                    <Button @click="submitPwd()" class="btn">确定</Button>
                </div>
            </Modal>
        </Layout>
</template>

<script>
// 192.168.0.200:8888/pc/login_out
import { removeCookies, } from '@/utils/helper'
import { loginOut,updataPwd } from "@/api/index";
  export default {
    data(){
      return{
        showPwd:false,
        innerHeight:0,
        searchContent:'',
        formData: {
          old_pwd: '',
          new_pwd: ''
        }
      }
    },
    computed: {
      muen(){
        return this.$store.state.menus
      },
      userInfo(){
        return this.$store.state.userInfo
      },
      auth(){
        return this.$store.state.auth
      }
    },
    created(){
      this.innerHeight = window.innerHeight - 2;
    },
    methods:{
      closeForm(){
        this.showPwd = false
        this.formData.old_pwd = ''
        this.formData.new_pwd = ''
      },
      submitPwd(){
        if(this.formData.old_pwd=='')return this.$Message.error('请输入原密码')
        if(this.formData.new_pwd=='')return this.$Message.error('请输入密码')
        updataPwd(this.formData).then( res=>{
            this.$Message.success(res.msg)
            localStorage.clear()
            removeCookies('sytoken')
            this.$router.push({path: '/'})
        }).catch(err=>{
            this.$Message.error(err.msg)
        })
      },
      goPwd(){
        this.showPwd = true
      },
      logout() {      
        loginOut().then( res=>{
            this.$Message.success(res.msg)
            localStorage.clear()
            removeCookies('sytoken')
            this.$router.push({path: '/'})
        }).catch(err=>{
            this.$Message.error(err.msg)
        })
        
        
      },
      inputchange(){
        this.$store.commit('setSearch', this.searchContent)
      },
    }
  }
</script>

<style scoped>
.layout{
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}
.layout-logo{
    width: 100px;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    float: left;
    position: relative;
    top: 15px;
    left: 20px;
}
.layout-nav{
    /* width: 420px; */
    margin: 0 auto;
    margin-right: 20px;
}
.ivu-layout-header{
  background: #FFFFFF;
  box-shadow: #F3EEE2 0px 3px 8px;
  z-index: 9;
}
.ivu-layout-sider{
  box-shadow: #F3EEE2 0px 1px 4px;
}
.ivu-layout-content{
  border-radius: 8px;
}
.ivu-menu-vertical.ivu-menu-light:after{
  background: none;
}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu){
  color: #fff;
  background: #201cc1;
  justify-content: center;
}
.ivu-menu-vertical .ivu-menu-item, .ivu-menu-vertical .ivu-menu-submenu-title{
  color: #000;
  justify-content: center;
}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) i{
  color: #0893FF;
}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after{
  background: unset;
}
.ivu-menu-light{
  margin: 20px 0;
}
.title_nav_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.right_box{
  display: flex;
  align-items: center;
}
.layout-nav {
  /* width: 200px; */
  margin: 0 auto;
  margin-right: 20px;
  position: relative;
}
.layout-nav:hover .show-menu {
  display: inline-block;
}
.bg-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #201cc1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.show-menu {
  display: none;
  position: absolute;
  width: 100px;
  height: 120px;
  /* right: 30px; */
  top: 60px;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 #ebeef5;
  text-align: center;
}
.show-menu div {
  cursor: pointer;
}
.show-menu div:hover{
  background: #d7dde4;
}
.ml-5{
  margin-left: 5px;
}
.ml-20{
  margin-left: 20px;
}
</style>
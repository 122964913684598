import request from '@/utils/request'

export function login (data) {
    return request({
        url: '/login/login',
        method: 'post',
        data
    })
}
//退出登录
export function loginOut (data) {
    return request({
        url: '/login/logout',
        method: 'post',
        data
    })
}
//修改密码
export function updataPwd (data) {
    return request({
        url: '/login/modify',
        method: 'post',
        data
    })
}
export function getUserList (data) {
    return request({
        url: '/user/index',
        method: 'get',
        params: data
    })
}

export function modifyUserStatus (data) {
    return request({
        url: '/user/status',
        method: 'post',
        data
    })
}

export function deleteUser (data) {
    return request({
        url: '/user/delete',
        method: 'post',
        data
    })
}
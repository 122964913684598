import Vue from 'vue'
import VueRouter from 'vue-router'
import mainLayout from "@/components/mainLayout"
import index from "./modules/index"
import member from "./modules/member" //会员信息


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      name: '登录',
      path: '/',
      component: () => import("@/pages/login/login.vue")
    },
    {
      path: '/admin',
      meta: { title: '手游会员系统'},
      component: mainLayout,
      children: [
        ...index,      
        member
      ]
    },
  ]
})


router.beforeEach((to, from, next) => {
  window.document.title = "手游会员系统";
  next();
})
export default router
